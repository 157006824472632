.carousel {
  --text-color: white;
  padding: 0 15dvw;
  .slide {
    flex: 0 0 700px;
    max-width: 80dvw;
    img {
      filter: grayscale(0);
      transition: all 0.25s ease;
    }
    &.active {
      img {
        filter: none;
      }
    }
  }
}
