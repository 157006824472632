.carousel {
  padding-left: 15dvw;
  padding-right: 15dvw;
  .slide {
    flex: 0 0 70dvw;

    @media screen and (max-width: 768px) {
      flex: 0 0 90dvw;
    }

    img {
      filter: grayscale(0);
      transition: all 0.25s ease;
    }
    &.active {
      img {
        filter: none;
      }
    }
  }
}
